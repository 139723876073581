export const plans = {
  basic: {
    name: 'Basic',
    pricePerUserMonthly: 12,
    featureSet: {
      unlimitedSnipets: true,
      unlimitedPlaybooks: true,
      unlimitedCollections: false,
      sybg: true,
      googleAuth: true,
      simpleAccessControl: false,
      advancedAccessControl: false,
      suggestions: false,
      simpleInsights: false,
      advancedInsights: false,
      urgentSupport: false,
    },
  },
  team: {
    name: 'Team',
    pricePerUserMonthly: 17,
    featureSet: {
      unlimitedSnipets: true,
      unlimitedPlaybooks: true,
      unlimitedCollections: true,
      sybg: true,
      googleAuth: true,
      simpleAccessControl: true,
      suggestions: true,
      simpleInsights: true,
      advancedAccessControl: false,
      advancedInsights: false,
      urgentSupport: false,
    },
  },
  enterprise: {
    name: 'Enterprise',
    pricePerUserMonthly: 24,
    featureSet: {
      unlimitedSnipets: true,
      unlimitedPlaybooks: true,
      unlimitedCollections: true,
      sybg: true,
      googleAuth: true,
      simpleAccessControl: true,
      advancedAccessControl: true,
      suggestions: true,
      simpleInsights: true,
      advancedInsights: true,
      urgentSupport: true,
    },
  },
};
